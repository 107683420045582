/*
 * @Author: liuzhixiang 
 * @Date: 2021-02-06 14:49:09 
 * @Last Modified by: liuzhixiang
 * @Last Modified time: 2021-10-Sa 03:16:26
 */
<template>
  <div class="teacherInformation">
    <div class="teacher_contop">
      <div class="width_1200">
        <div class="teacherpic_box" v-if="route.type == 3">
          <img :src="teacherInfo.HeadImg" class="headImg" alt />
          <div class="teacherinfo">
            <p>
              {{ teacherInfo.SupplierName }}
              <img
                src="@/assets/images/icon_qian.png"
                alt=""
                v-if="teacherInfo.IsSign == 1"
                class="icon_qian"
                srcset=""
              />
            </p>
          </div>
        </div>
        <div class="teacherpic_box" v-if="route.type != 3">
          <img :src="teacherInfo.HeadImg" class="headImg" alt />
          <div class="teacherinfo">
            <p>{{ teacherInfo.Name }}</p>
            <p>{{ teacherInfo.ShortTitle }}</p>
          </div>
        </div>
      </div>
      <div class="cont" v-if="route.type == 1">
        <div class="cont_top width_1200">
          <div class="width_1200">
            <p
              :class="titlesIndex == i ? 'active' : ''"
              v-for="(ti, i) in titles"
              :key="i"
            >
              <span
                @click="titlesClick(i)"
                v-if="i == 0 && teacherInfo.Descript"
                >{{ ti }}</span
              >
              <span
                @click="titlesClick(i)"
                v-if="i == 1 && produces.length > 0"
                >{{ ti }}</span
              >
              <span
                @click="titlesClick(i)"
                v-if="i == 2 && zbList.data.length > 0"
                >{{ ti }}</span
              >
              <span
                @click="titlesClick(i)"
                v-if="i == 3 && dbList.data.length > 0"
                >{{ ti }}</span
              >
              <span
                @click="titlesClick(i)"
                v-if="i == 4 && msList.data.length > 0"
                >{{ ti }}</span
              >
            </p>
          </div>
        </div>
        <div class="cont_lists bgc_f5f5f5">
          <div class="width_1200">
            <div id="introduction" v-if="teacherInfo.Descript">
              <div class="title_dom">简介</div>
              <div
                class="descript"
                v-if="teacherInfo.Remark"
                v-html="teacherInfo.Remark"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="scrollProd" v-if="produces.length > 0">
              <div class="title_dom">讲师作品</div>
              <ul class="cont_li">
                <li
                  class="hover_shadow"
                  v-for="(item, index) in produces"
                  :key="index"
                  @click="goToProd(item)"
                >
                  <img
                    v-lazy="item.CoverImg"
                    class="coverImg"
                    alt=""
                    srcset=""
                  />
                  <div class="count">国画(共{{ item.ImgCount }}张)</div>
                </li>
              </ul>
            </div>
            <div id="scrollZbcourse" v-if="zbList.data.length > 0">
              <div class="title_dom">直播课程</div>
              <ul class="cont_li cont_li2">
                <li
                  class="hover_shadow"
                  v-for="(item, index) in zbList.data"
                  :key="index"
                  @click="goTo(item)"
                >
                  <img
                    v-lazy="item.CoverImg"
                    class="coverImg1"
                    alt=""
                    srcset=""
                  />
                  <div class="title2 decimalPoint">{{ item.Name }}</div>
                  <div class="perPrice">
                    <div
                      class="per"
                      v-for="(it, i) in item.TeacherInfo.slice(0, 2)"
                      :key="i"
                    >
                      <div>
                        <img
                          v-lazy="it.HeadImg"
                          class="head"
                          alt=""
                          srcset=""
                        />
                        <span>{{ it.Name }}</span>
                      </div>
                    </div>
                    <div
                      class="price"
                      v-if="
                        item.IsCharge === true && item.OriginalPrice != null
                      "
                    >
                      ￥{{ item.OriginalPrice }}
                    </div>
                    <div v-else>免费</div>
                  </div>
                </li>
              </ul>
            </div>
            <div id="scrollDbcourse" v-if="dbList.data.length > 0">
              <div class="title_dom">点播课程</div>
              <ul class="cont_li cont_li2">
                <li
                  class="hover_shadow"
                  v-for="(item, index) in dbList.data"
                  :key="index"
                  @click="goTo(item)"
                >
                  <img
                    v-lazy="item.CoverImg"
                    class="coverImg1"
                    alt=""
                    srcset=""
                  />
                  <div class="title2 decimalPoint">{{ item.Name }}</div>
                  <div class="perPrice">
                    <div
                      class="per"
                      v-for="(it, i) in item.TeacherInfo.slice(0, 2)"
                      :key="i"
                    >
                      <div>
                        <img
                          v-lazy="it.HeadImg"
                          class="head"
                          alt=""
                          srcset=""
                        />
                        <span>{{ it.Name }}</span>
                      </div>
                    </div>
                    <div
                      class="price"
                      v-if="
                        item.IsCharge === true && item.OriginalPrice != null
                      "
                    >
                      ￥{{ item.OriginalPrice }}
                    </div>
                    <div v-else>免费</div>
                  </div>
                </li>
              </ul>
            </div>
            <div id="scrollMscourse" v-if="msList.data.length > 0">
              <div class="title_dom">面授课程</div>
              <ul class="cont_li cont_li2">
                <li
                  class="hover_shadow"
                  v-for="(item, index) in msList.data"
                  :key="index"
                  @click="goTo(item)"
                >
                  <img
                    v-lazy="item.CoverImg"
                    class="coverImg1"
                    alt=""
                    srcset=""
                  />
                  <div class="title2 decimalPoint">{{ item.Name }}</div>
                  <div class="perPrice">
                    <div
                      class="per"
                      v-for="(it, i) in item.TeacherInfo.slice(0, 2)"
                      :key="i"
                    >
                      <div>
                        <img
                          v-lazy="it.HeadImg"
                          class="head"
                          alt=""
                          srcset=""
                        />
                        <span>{{ it.Name }}</span>
                      </div>
                    </div>
                    <div
                      class="price"
                      v-if="
                        item.IsCharge === true && item.OriginalPrice != null
                      "
                    >
                      ￥{{ item.OriginalPrice }}
                    </div>
                    <div v-else>免费</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="cont" v-if="route.type == 2">
        <div class="cont_top width_1200">
          <div class="width_1200">
            <span
              :class="titlesIndex == i ? 'active' : ''"
              v-for="(ti, i) in titlesOne"
              :key="i"
              @click="titlesClickOne(i)"
              >{{ ti }}</span
            >
          </div>
        </div>
        <div class="cont_lists bgc_f5f5f5">
          <div class="width_1200">
            <div id="introduction" v-if="teacherInfo.Descript">
              <div class="title_dom">简介</div>
              <div class="descript" v-html="teacherInfo.Descript"></div>
            </div>
            <div id="scrollProd">
              <div class="title_dom">作品</div>
              <ul class="cont_li" v-if="produces.length > 0">
                <li
                  class="hover_shadow"
                  v-for="(item, index) in produces"
                  :key="index"
                >
                  <router-link
                    :to="{
                      path: '/fineArts/produceDetail',
                      query: {
                        id: item.Id,
                      },
                    }"
                  >
                    <img
                      v-lazy="item.CoverImg"
                      class="coverImg"
                      alt=""
                      srcset=""
                    />
                  </router-link>
                </li>
              </ul>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="cont" v-if="route.type == 3">
        <div class="cont_top width_1200">
          <div class="width_1200">
            <span
              :class="titlesIndex == i ? 'active' : ''"
              v-for="(ti, i) in titlesArtist"
              :key="i"
              @click="titlesClickOne(i)"
              >{{ ti }}</span
            >
          </div>
        </div>
        <div class="cont_lists bgc_f5f5f5">
          <div class="width_1200">
            <div id="introduction">
              <div class="title_dom">简介</div>
              <div
                class="descript"
                v-if="teacherInfo.Remark"
                v-html="teacherInfo.Remark"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="scrollProd">
              <div class="title_dom">作品</div>
              <div v-if="artList.length > 0">
                <ul class="cont_li">
                  <li
                    class="hover_shadow"
                    v-for="(item, index) in artList"
                    :key="index"
                  >
                    <router-link
                      :to="{
                        path: '/art/artistsDetails',
                        query: {
                          id: item.ArtId,
                          artUserId: artUserId,
                        },
                      }"
                    >
                      <img
                        v-lazy="item.ArtImg"
                        class="coverImg"
                        alt=""
                        srcset=""
                      />
                    </router-link>
                  </li>
                </ul>
                <div class="width_1200">
                  <el-pagination
                    background
                    @size-change="handleSizeChangeProd"
                    @current-change="handleCurrentChangeProd"
                    :current-page.sync="page"
                    :page-size="pageSize"
                    layout="prev, pager, next"
                    :total="totalProd"
                  >
                  </el-pagination>
                </div>
              </div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="exhibitionExperience">
              <div class="title_dom">参展经历</div>
              <div
                class="descript"
                v-if="teacherInfo.ExhibitionExperience"
                v-html="teacherInfo.ExhibitionExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="awardWinningExperience">
              <div class="title_dom">获奖经历</div>
              <div
                class="descript"
                v-if="teacherInfo.AwardExperience"
                v-html="teacherInfo.AwardExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="publishingExperience">
              <div class="title_dom">出版经历</div>
              <div
                class="descript"
                v-if="teacherInfo.PublishingExperience"
                v-html="teacherInfo.PublishingExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
            <div id="auctionRecord">
              <div class="title_dom">拍卖记录</div>
              <div
                class="descript"
                v-if="teacherInfo.AuctionExperience"
                v-html="teacherInfo.AuctionExperience"
              ></div>
              <div v-else>
                <no-data></no-data>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryTeacherInfo, commonQueryTeacherProduce, courseQueryCourseList } from "@/api/home";
import { queryArtistDetail, queryArtistProduce } from "@/api/fineArts";
import { querySupplierById, queryNewestArtExhibition } from "@/api/art";
import noData from "@/components/noData";
export default {
  data () {
    return {
      teacherInfo: {},
      artList: [],
      titles: ["简介", "讲师作品", "直播课程", "点播课程", "面授课程"],
      titlesOne: ["简介", "作品"],
      titlesArtist: ["简介", "作品", "参展经历", "获奖经历", "出版经历", "拍卖记录"],
      titlesIndex: 0,
      produces: { data: [] },
      dbList: { data: [] },
      msList: { data: [] },
      zbList: { data: [] },
      page: 1,
      pageSize: 4,
      totalProd: 0,
      route: {},
      artUserId: ''
    };
  },
  components: {
    noData
  },
  created () {
  },
  mounted () {
    //type 1讲师详情 2专家详情 3艺术家详情
    this.route = this.$route.query
    if (this.route.type == 1) {
      document.title = "讲师详情"
      this.getQueryTeacherInfo()
      this.getCommonQueryTeacherProduce()
    } else if (this.route.type == 2) {
      document.title = "专家详情"
      this.getDueryArtistDetail()
      this.getQueryArtistProduce()
    } else if (this.route.type == 3) {
      document.title = "艺术家详情"
      this.getQuerySupplierById()
      this.getQueryNewestArtExhibition()
      this.artUserId = JSON.parse(localStorage.getItem("userInfo")).Id
    }
  },
  methods: {
    handleSizeChangeProd (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChangeProd (val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getQueryNewestArtExhibition()
    },
    titlesClickOne (i) {
      this.titlesIndex = i;
      let id = ""
      if (i == 0) {
        id = "introduction"
      } if (i == 1) {
        id = "scrollProd"
      } else if (i == 2) {
        id = "exhibitionExperience"
      } else if (i == 3) {
        id = "awardWinningExperience"
      } else if (i == 4) {
        id = "publishingExperience"
      } else if (i == 5) {
        id = "auctionRecord"
      }
      document.getElementById(id).scrollIntoView()
    },
    // 艺术家详情
    async getQuerySupplierById () {
      let parm = "?id=" + this.$route.query.id
      const res = await querySupplierById(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
      } else {
        this.$message.error(res.msg);
      }
    },
    // 艺术家作品
    async getQueryNewestArtExhibition () {
      let parm = {
        ArtistId: this.$route.query.id,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      }
      const res = await queryNewestArtExhibition(parm);
      if (res.success == true) {
        this.artList = res.response.data
        this.totalProd = res.response.dataCount
      }
    },
    // 专家详情
    async getDueryArtistDetail () {
      let parm = "?artistId=" + this.$route.query.id
      const res = await queryArtistDetail(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
        this.teacherInfo.Name = this.teacherInfo.ArtistName
        this.teacherInfo.ShortTitle = this.teacherInfo.BriefIntroduce
        this.teacherInfo.Descript = this.teacherInfo.Introduce
      } else {
        this.$message.error(res.msg);
      }
    },
    // 专家作品
    async getQueryArtistProduce () {
      let parm = "?artistId=" + this.$route.query.id
      const res = await queryArtistProduce(parm);
      if (res.success == true) {
        this.produces = res.response
      } else {
        this.$message.error(res.msg);
      }
    },
    goToProd (obj) {
      let ob = {
        Id: obj.Id,
      };
      this.$router.push({ path: "/teacher/teacherWorksDetails", query: ob, type: this.route.type });
    },
    goTo (obj, type) {
      let ob = {
        Id: obj.Id,
        Name: obj.Name,
        CoursePackage: obj.CoursePackage,
      };
      this.$router.push({ path: "/course/details", query: ob });
    },
    titlesClick (i, idv) {
      this.titlesIndex = i;
      let id = ''
      if (i == 0) {
        id = "introduction"
      } if (i == 1) {
        id = "scrollProd"
      } else if (i == 2) {
        id = "scrollZbcourse"
      } else if (i == 3) {
        id = "scrollDbcourse"
      } else if (i == 4) {
        id = "scrollMscourse"
      }
      document.getElementById(id).scrollIntoView()
    },
    // 作品列表
    async getCommonQueryTeacherProduce () {
      let parm = "?teacherId=" + this.$route.query.id
      const res = await commonQueryTeacherProduce(parm);
      if (res.success == true) {
        this.produces = res.response
      } else {
        this.$message.error(res.msg);
      }
    },
    // 课程
    async getCourseQueryCourseList (type) {
      let parm = {}
      parm.courseType = type // 	课程类型（0直播1点播2面授）
      parm.TeacherName = this.teacherInfo.Name
      parm.pageIndex = this.page
      parm.pageSize = this.pageSize
      const res = await courseQueryCourseList(parm);
      if (res.success == true) {
        if (type == 0) {
          this.zbList = res.response
        } else if (type == 1) {
          this.dbList = res.response
        } else if (type == 2) {
          this.msList = res.response
        }
      } else {
        this.$message.error(res.msg);
      }
    },
    // 教师信息
    async getQueryTeacherInfo () {
      let parm = {}
      parm.teacherId = this.$route.query.id
      const res = await queryTeacherInfo(parm);
      if (res.success == true) {
        this.teacherInfo = res.response
        this.getCourseQueryCourseList(1)
        this.getCourseQueryCourseList(2)
        this.getCourseQueryCourseList(0)
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.descript {
  padding: 28px 20px;
  line-height: 28px;
  color: #555;
  background-color: #fff;
  img {
    max-width: 100%;
  }
}
.teacherInformation {
  .teacher_contop {
    min-height: 500px;
    z-index: 0;
    background: #fff url(../../assets/images/personal_banner_bg.png) no-repeat
      100% 0;
  }
  .teacherpic_box {
    // display: flex;
    // align-items: center;
    // margin-top: -390px;
    padding-top: 100px;
    text-align: center;
    .headImg {
      border: 6px solid #fff;
      width: 220px;
      height: 220px;
      border-radius: 100%;
      // display: block;
      overflow: hidden;
      text-align: center;
      // margin-left: 40px;
      // margin-right: 25px;
      background: #fff;
    }
    .teacherinfo {
      .icon_qian {
        width: 20px;
        height: 20px;
        margin-left: 5px;
      }
      p {
        &:nth-child(1) {
          font-size: 30px;
          line-height: 1;
          color: #333333;
          text-align: center;
          margin: 20px 0;
        }
        &:nth-child(2) {
          font-size: 14px;
          line-height: 1.5;
          color: #333333;
          width: 750px;
          margin: 0 auto;
        }
      }
      .descript {
        width: 825px;
        line-height: 1.5;
        font-size: 14px;
        color: #333333;
        margin: 0 auto;
        padding: 22px 0;
      }
    }
  }
  .introduce {
    background: #fff;
    margin-top: 35px;
    margin-bottom: 20px;
    .title {
      font-size: 16px;
      color: #111;
      height: 50px;
      line-height: 50px;
      padding: 0px 20px;
      border-bottom: 1px solid #eee;
    }
  }

  .cont {
    .cont_top {
      height: 50px;
      background: #ffffff;
      span,
      p {
        display: inline-block;
        margin-right: 30px;
        line-height: 50px;
        font-size: 16px;
        cursor: pointer;
      }
      p {
        span {
          margin: 0 auto;
        }
      }
      .active {
        color: #1288f4;
        position: relative;
        &::before {
          content: "";
          width: 100%;
          height: 2px;
          background-color: #1288f4;
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    .cont_lists {
      min-height: 200px;
      margin-bottom: 20px;
      .cont_li {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 280px;
          height: 280px;
          margin-bottom: 10px;
          margin-right: 26px;
          // flex: 0 0 280px;
          position: relative;
          border: 1px solid #e7e7e7;
          background-color: #fff;
          &:nth-child(4n) {
            margin-right: 0;
          }
          .coverImg {
            width: 278px;
            height: 278px;
          }
          .count {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #2b6efb;
            padding: 10px;
            font-size: 13px;
            color: #fff;
          }

          .title2 {
            padding: 0 20px;
            font-size: 20px;
            font-weight: bold;
            color: #333333;
            line-height: 1;
            margin-bottom: 14px;
            margin: 18px 0;
          }
          .perPrice {
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;
            color: #666666;
            .per {
              .head {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                margin: 0;
              }
              span {
                font-size: 14px;
                margin: 0 5px;
              }
            }
            .price {
              font-size: 22px;
              font-weight: bold;
              color: #fe4a4a;
              span {
                font-size: 0.625rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .cont_li2 {
        .coverImg1 {
          width: 100%;
          height: 160px;
        }
      }
    }
  }
}
/deep/.el-pagination {
  text-align: right;
}
</style>